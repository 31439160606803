import React from 'react';
import Web3 from 'web3';
import axios from 'axios';


const WithdrawLoading = (props) => {
  return (

    <div className="row justify-content-center">
      <div className="col-1 justify-content-right pr-0">
        <div className="shape-img4 pl-5">


        </div>
      </div>
      <div className="col-2 pl-2">
        <p>Loading...</p>
      </div>
    </div>
  )
}

class Migrationsv1tov2 extends React.Component {



  async weStopFullSale() {

    console.log("<-- INSIDE STOP SALE--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.pauseFullSale().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  async weRestartFullSale() {

    console.log("<-- INSIDE RESTART SALE--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.restartFullSale().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  async werestartPreSaleMulti() {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.restartPreSaleMulti().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }




  async wepausePreSaleMulti() {

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.pausePreSaleMulti().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }


  async wepauseVIPWhitelisting() {

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.pauseVIPWhitelisting().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  async weStopPreSale() {

    console.log("<-- INSIDE STOP PRE SALE--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.pausePreSale().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }


  async weRestartPreSale() {

    console.log("<-- INSIDE STOP PRE SALE--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.restartPreSale().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }




  async weMigrate(mto, maddress) {

    console.log("<-- INSIDE MIGRATIONS--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.BulkManualMint(mto, maddress).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }




  async weImport(amount,worktype) {

    if (this.props.contract !== 'undefined') {

      try {

          var  setsize ;

        if (amount > 0)
        { setsize = amount;  }
        else
        { setsize = "40"; }


//        console.log("set size",setsize); f

        //check if this account is already registered in the DB, by calling the route
        const res = await axios.get("/importaddresses?amount="+setsize.toString()+"&worktype="+worktype, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
          }
        });

        //console.log("res",res["data"]);

        //adding that array to the right box
        var  myimport = ""

        for (var j = 0; j < res["data"].length; j++){

            if (j == 0)
            {
                //myimport=res["data"][0]
                console.log("step 0...",res["data"])
                this.props.addWhishslists(res["data"][0])
            }
            else
            {
                console.log("more steps...",res["data"])
                //myimport = myimport+","+res["data"][j]
                this.props.addWhishslists(res["data"][j])

            }

        }


        //document.getElementById('listofad').value = myimport;
        //this.props.addWhishslists(myimport)


      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }






    async weChangeVIPWhitelistingPrice(mto) {


      if (this.props.contract !== 'undefined') {
        try {

          await this.props.contract.methods.setVIPAccessPrice(mto).send({ from: this.props.account })

        } catch (e) {
          console.log('Error, withdraw: ', e)
        }
      }
    }






  async wesetVIPWhitelistSupply(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setVIPWhitelistSupply(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  async wesetMaxTokens(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        console.log("incoming value for max tokens",mto);
        await this.props.contract.methods.setMaxTokens(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }


  async wesetMaxTokensPresale(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setMaxTokensPresale(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }


  async wesetNFTBatchFullSale(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setNFTBatchFullSale(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }




  async weremoveAddressFromWhiteList(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.removeAddressFromWhiteList(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  async wesetBaseURI(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setBaseURI(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }




  async wesetNFTBatchPreSale(mto) {


    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setNFTBatchPreSale(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }


  async weChangePrice(mto) {

    console.log("<-- INSIDE MIGRATIONS--> ")
    console.log("this.props.account --> ", this.props.account)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.setPrice(mto).send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }



  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      action: "wait"

    };

    // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
  }


  render() {
    return (
      <section className="what-we-do-area bg-black ">

        <div className="container ">
          {this.props.ownerAccount != null ?
            <div className="row justify-content-center">


              <div className="col-md-8 col-md-push-1 col-sm-10 col-sm-push-1 justify-content-center">
                <div className="d-flex flex-column justify-content-center text-center">

                    <form className="mb-0" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.weStopFullSale()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Nothing to write'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='STOP FULL SALE' />

                    </form>


                    <form className="mb-0" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.weRestartFullSale()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Nothing to write'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='RESTART FULL SALE' />

                    </form>


                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.weStopPreSale()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Nothing to write - Stopping the presale'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='STOP PRE SALE' />

                    </form>


                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.weRestartPreSale()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Nothing to write -- restarting the presale'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='RESTART PRE SALE' />

                    </form>





                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.wepauseVIPWhitelisting()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Pause the VIP WHITELISTING'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='PAUSE VIP WHITELISTING' />

                    </form>








                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.werestartVIPWhitelisting()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Just restarting the VIP WHITELISTING'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='RESTART VIP WHITELISTING' />

                    </form>




                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.werestartPreSaleMulti()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='ALLOW MULTI-BUY -- Allowed by default'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='ALLOW MULTI-BUY AT PRE-SALE' />

                    </form>



                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      this.wepausePreSaleMulti()


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='DIS-ALLOW MULTI-BUY -- Allowed by default'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='DIS-ALLOW MULTI-BUY AT PRE-SALE' />

                    </form>




                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh
                      const tkprice = this.mprice.value.toString()

                      console.log(" trying to change price to : ", tkprice)

                      this.weChangePrice(Web3.utils.toWei(tkprice, 'ether'))

                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='0.05'
                        ref={(input) => { this.mprice = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Change Price of Token - example 0.05ETH' />

                    </form>


                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh
                      const tkprice = this.vipprice.value.toString()

                      console.log(" trying to change VIP whitelisting price to : ", tkprice)

                      this.weChangeVIPWhitelistingPrice(Web3.utils.toWei(tkprice, 'ether'))

                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='0.01'
                        ref={(input) => { this.vipprice = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Change Price of VIP Whitelisting - example 0.01ETH' />

                    </form>


                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tknumber = this.msize.value

                      this.wesetVIPWhitelistSupply( tknumber)

                    }}>



                      <input
                        type='text'
                        className="form-control "
                        placeholder='How many Whitelisting spots, ex:300'
                        ref={(input) => { this.msize = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Change quota of VIP Whitelisting spots' />

                    </form>





                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh
                      const tkprice = this.nftfullsale.value.toString()

                      console.log("changing max NFT supply to  : ", tkprice)

                      this.wesetMaxTokens(tkprice)

                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='MAX NFT SUPPLY ex: 9999'
                        ref={(input) => { this.nftfullsale = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='SET MAX NB of NFTs available - Full Sale' />

                    </form>





            <form className="" onSubmit={(e) => {
              e.preventDefault()    //doesnt let the page refresh
              const tkprice = this.nftfullsale2.value.toString()

              console.log("changing max NFT pre-sale supply to  : ", tkprice)

              this.wesetMaxTokensPresale(tkprice)

            }}>


              <input
                type='text'
                className="form-control "
                placeholder='MAX NFT SUPPLY ex: 3000'
                ref={(input) => { this.nftfullsale2 = input }}
              />

              <input type='submit' className='btn btn-block btn-primary mb-3' value='SET MAX NB of NFTs available PRESALE' />

            </form>





                <form className="" onSubmit={(e) => {
                  e.preventDefault()    //doesnt let the page refresh

                  const tknumber = this.msizefullfull.value.toString()

                  this.wesetNFTBatchFullSale( tknumber)

                }}>

                  <input
                    type='text'
                    className="form-control "
                    placeholder='Max buy size FULL SALE ex:5'
                    ref={(input) => { this.msizefullfull = input }}
                  />

                  <input type='submit' className='btn btn-block btn-primary mb-3' value='Max batch to be bought at once - Full sale' />

                </form>





                <form className="" onSubmit={(e) => {
                  e.preventDefault()    //doesnt let the page refresh

                  const tknumber = this.msizeprepre.value.toString()

                  this.wesetNFTBatchPreSale( tknumber)

                }}>

                  <input
                    type='text'
                    className="form-control "
                    placeholder='Max buy size PRE SALE ex:3'
                    ref={(input) => { this.msizeprepre = input }}
                  />

                  <input type='submit' className='btn btn-block btn-primary mb-3' value='Max batch to be bought at once - Pre-sale' />

                </form>




                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tknumber = this.msize3.value
                      const tkaddress = this.maddress2.value

                      this.weMigrate(tkaddress, tknumber)


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Destination address'
                        ref={(input) => { this.maddress2 = input }}
                      />


                      <input
                        type='text'
                        className="form-control "
                        placeholder='size - 20 max'
                        ref={(input) => { this.msize3 = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Migrate General' />

                    </form>




                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tkaddress = this.maddress.value

                      this.weremoveAddressFromWhiteList(tkaddress)


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='Address to remove from WL'
                        ref={(input) => { this.maddress = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Remove Address from Whitelist' />

                    </form>





                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tkaddress = this.maddressuri.value

                      this.wesetBaseURI(tkaddress)


                    }}>

                      <input
                        type='text'
                        className="form-control "
                        placeholder='New URI to use, ex: https://www.niftycash.com/api/v1/videos/'
                        ref={(input) => { this.maddressuri = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Change URI - DONT use quotes' />

                    </form>





                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tknumber = this.msizeimport.value

                      this.weImport(tknumber,"withwork")


                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='size - ex:40'
                        ref={(input) => { this.msizeimport = input }}
                      />



                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Import Addresses to send to Contract (WITH INVITES)' />

                    </form>




                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tknumber = this.msizeimport2.value

                      this.weImport(tknumber,"withoutwork")


                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='size - ex:40'
                        ref={(input) => { this.msizeimport2 = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Import Addresses to send to Contract (NO INVITES or ACTIVITY)' />

                    </form>

                    <form className="" onSubmit={(e) => {
                      e.preventDefault()    //doesnt let the page refresh

                      const tknumber = this.msizeimport2.value

                      this.weImport(tknumber,"both")


                    }}>


                      <input
                        type='text'
                        className="form-control "
                        placeholder='size - ex:40'
                        ref={(input) => { this.msizeimport2 = input }}
                      />

                      <input type='submit' className='btn btn-block btn-primary mb-3' value='Import Addresses to send to Contract ( both INVITES and NO INVITES)' />

                    </form>



                </div>
              </div>

            </div>

            : <WithdrawLoading />}

        </div>


      </section>
    )
  }
}

export default Migrationsv1tov2;
